@use '../base/variables' as v;
@use '../base/mixins' as m;
.case-title-container {
    position: relative;
    &>div {
        position: relative;
    }
    button {
        border: 1px solid #333;
        color: #333;
        border-radius: 50%;
        background: none;
        font-size: 1.5em;
        z-index: 100;
        width: 40px;
        height: 40px;
        @include m.tablet {
            width: 50px;
            height: 50px;
        }
        span {
            width: 50%;
            height: 2px;
            transform: rotate(45deg);
            margin-left: 25%;
            &:last-of-type {
                transform: rotate(-45deg);
                margin-top: -2px;
            }
        }
        &:hover {
            transform: rotate(90deg);
        }
    }
}

.case-media {
    .case-video {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        margin-bottom: 1.25rem;
        @include m.desktop {
            margin-bottom: 0rem;
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
    .image-main {
        margin-bottom: .5rem;
        @include m.desktop {
            margin-bottom: 0rem;
        }
        img {
            width: 100%;
            height: 230px;
            object-fit: cover;
            @include m.tablet {
                height: 400px;
            }
            @include m.desktop {
                height: 600px;
            }
        }
    }
    .image-more {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
        @include m.tablet {
            flex-direction: row;
        }
        @include m.desktop {
            flex-direction: column;
            gap: 1.25rem;
        }
        img {
            width: 100%;
            height: 230px;
            object-fit: cover;
            @include m.desktop {
                height: 290px;
            }
        }
        &.images-more-video {
            img {
                height: 230px;
                @include m.desktop {
                    height: 163px;
                }
                @include m.xdesktop {
                    height: 197px;
                }
                @include m.xxdesktop {
                    height: 231px;
                }
            }
        }
    }
}

.case-media,
.case-gallery {
    a {
        position: relative;
        padding: 0px;
        &:after {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background: #000;
            z-index: 100;
            opacity: 0%;
            visibility: hidden;
            transition: all .3s;
        }
        i {
            position: absolute;
            z-index: 101;
            top: 50%;
            left: 50%;
            font-size: 2em;
            margin-left: -24px;
            margin-top: -24px;
            opacity: 0%;
            visibility: hidden;
            transition: all .6s;
            transform: translateY(100px);
        }
        &:hover {
            &:after {
                opacity: 40%;
                visibility: visible;
            }
            i {
                opacity: 100%;
                visibility: visible;
                transform: translateY(0px);
            }
        }
    }
}

.case-gallery {
    a {
        img {
            width: 100%;
            height: 230px;
            object-fit: cover;
        }
        @include m.tablet {
            margin-bottom: .5rem;
        }
    }
}

.content-text {
    p,
    ul,
    blockquote,
    .blockquote {
        margin-bottom: 1.5rem;
    }
    h1,
    h1,
    h3,
    h4 {
        margin-bottom: 1.5rem;
        line-height: 1.3em;
    }
    @include m.desktop {
        p,
        ul,
        blockquote,
        .blockquote,
        h1,
        h1,
        h3,
        h4 {
            margin-bottom: 2.25rem;
        }
    }
    ul {
        padding-left: 0px;
        li {
            padding-left: 50px;
            margin-bottom: .5rem;
            position: relative;
            &:last-child {
                margin-bottom: 0rem;
            }
            &:after {
                content: '';
                mask: url('data:image/svg+xml;charset=utf-8,<svg class="css3animate" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="92px" height="41.204px" viewBox="0 0 92 41.204" enable-background="new 0 0 92 41.204" xml:space="preserve"><path d="M3.12,22.757H83.8L70.166,36.619c-0.818,0.833-0.808,2.178,0.025,2.997c0.806,0.794,2.202,0.782,2.998-0.024l17.018-17.309 C90.711,21.876,91,21.276,91,20.637c0-0.034-0.003-0.074-0.008-0.107c-0.01-0.192-0.047-0.385-0.095-0.52 c-0.008-0.048-0.036-0.151-0.054-0.195c-0.045-0.114-0.101-0.182-0.091-0.158c-0.063-0.12-0.135-0.232-0.19-0.298 c-0.031-0.058-0.124-0.19-0.17-0.237L73.189,1.632C72.788,1.225,72.252,1,71.679,1c-0.56,0-1.088,0.217-1.487,0.608 c-0.832,0.819-0.844,2.165-0.024,2.999l13.683,13.91H3.12c-1.169,0-2.12,0.951-2.12,2.12C1,21.806,1.951,22.757,3.12,22.757z"/> </svg>') no-repeat 50% 50%;
                mask-size: cover;
                height: 8px;
                width: 25px;
                position: absolute;
                top: 8px;
                left: 0px;
            }
        }
        &.columns-2 {
            column-count: 2;
        }
    }
    blockquote,
    .blockquote {
        font-size: 1.125em;
        font-family: var(--heading-font);
        font-weight: 700;
        font-style: italic;
        padding-left: 70px;
        position: relative;
        &:after {
            content: '';
            mask: url('data:image/svg+xml;charset=utf-8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 82.223" enable-background="new 0 0 100 82.223" xml:space="preserve"><g><path d="M25.79,79.723c-4.496,0-8.172-0.918-11.031-2.756c-2.863-1.84-5.21-4.186-7.049-7.047 c-2.25-3.268-3.677-6.844-4.289-10.727c-0.613-3.879-0.92-7.051-0.92-9.5c0-10.009,2.551-19.102,7.662-27.274 C15.266,14.25,23.233,7.607,34.064,2.5l2.757,5.516c-6.334,2.657-11.798,6.847-16.395,12.563 c-4.597,5.722-6.895,11.545-6.895,17.469c0,2.45,0.306,4.597,0.92,6.433c3.264-2.65,7.047-3.982,11.337-3.982 c5.311,0,9.907,1.739,13.79,5.209c3.879,3.479,5.823,8.275,5.823,14.404c0,5.723-1.944,10.418-5.823,14.096 C35.696,77.885,31.1,79.723,25.79,79.723z M77.886,79.723c-4.495,0-8.173-0.918-11.03-2.756c-2.865-1.84-5.212-4.186-7.051-7.047 c-2.25-3.268-3.675-6.844-4.29-10.727c-0.611-3.879-0.917-7.051-0.917-9.5c0-10.009,2.55-19.102,7.66-27.274 C67.362,14.25,75.33,7.607,86.16,2.5l2.757,5.516c-6.333,2.657-11.797,6.847-16.394,12.563c-4.597,5.722-6.895,11.545-6.895,17.469 c0,2.45,0.305,4.597,0.919,6.433c3.264-2.65,7.047-3.982,11.338-3.982c5.309,0,9.906,1.739,13.79,5.209 c3.879,3.479,5.824,8.275,5.824,14.404c0,5.723-1.945,10.418-5.824,14.096C87.792,77.885,83.194,79.723,77.886,79.723z"/></g></svg>') no-repeat 50% 50%;
            mask-size: cover;
            height: 33px;
            width: 40px;
            top: 0px;
            left: 0px;
            position: absolute;
            background-color: black;
        }
    }
    img {
        margin: 0 0 2rem 0;
        width: 100%;
        height: 200px;
        object-fit: cover;
        @include m.tablet {
            width: initial;
            height: auto;
            object-fit: inherit;
            &.note-float-right {
                margin: .5rem 0rem 1rem 2rem
            }
            &.note-float-left {
                margin: .5rem 2rem 1rem 0rem
            }
        }
    }
}

.light-scheme {
    .case-title-container {
        button {
            border: 1px solid var(--light-main-color);
            span {
                background: var(--light-main-color);
            }
            &:hover {
                background: var(--light-accent-color);
                border: 1px solid var(--light-accent-color);
                span {
                    background: var(--light-back-main-color);
                }
            }
        }
    }
    .case-media,
    .case-gallery {
        a {
            &:after {
                background: var(--light-accent-color);
            }
            i {
                color: var(--light-back-main-color);
            }
        }
    }
    .content-text {
        blockquote,
        .blockquote {
            color: var(--light-accent-color);
            &:after {
                background-color: var(--light-accent-color);
            }
        }
        ul {
            li {
                &:after {
                    background-color: var(--light-accent-color);
                }
            }
        }
    }
    .case-information {
        .case-information-list {
            background: var(--light-back-secondary-color);
        }
    }
}

.dark-scheme {
    .case-title-container {
        button {
            border: 1px solid var(--dark-main-color);
            span {
                background: var(--dark-main-color);
            }
            &:hover {
                background: var(--dark-accent-color);
                border: 1px solid var(--dark-accent-color);
                span {
                    background: var(--dark-back-main-color);
                }
            }
        }
    }
    .case-media,
    .case-gallery {
        a {
            &:after {
                background: var(--dark-accent-color);
            }
            i {
                color: var(--dark-back-main-color);
            }
        }
    }
    .content-text {
        blockquote,
        .blockquote {
            color: var(--dark-accent-color);
            &:after {
                background-color: var(--dark-accent-color);
            }
        }
        ul {
            li {
                &:after {
                    background-color: var(--dark-accent-color);
                }
            }
        }
    }
    .case-information {
        .case-information-list {
            background: var(--dark-back-secondary-color);
        }
    }
}