@use '../base/variables' as v;
@use '../base/mixins' as m;
#filters {
    button {
        cursor: pointer;
        font-size: .9em;
    }
}

.dark-scheme {
    #filters {
        button {
            border: 1px solid rgba(var(--dark-main-color-rgb), .5);
            background: transparent;
            color: var(--dark-head-color);
            &:hover,
            &.checked {
                color: var(--dark-back-main-color);
                background: var(--dark-accent-color);
                border: 1px solid var(--dark-accent-color);
            }
        }
    }
}

.light-scheme {
    #filters {
        button {
            border: 1px solid rgba(var(--light-main-color-rgb), .5);
            background: transparent;
            color: var(--light-head-color);
            &:hover,
            &.checked {
                color: var(--light-back-main-color);
                background: var(--light-accent-color);
                border: 1px solid var(--light-accent-color);
            }
        }
    }
}

#gallery {
    .grid-sizer {
        margin: 0px;
        float: left;
        border-radius: 0px;
        clear: none;
        overflow: hidden;
        position: absolute;
        width: 100%;
        @include m.tablet {
            width: 50%;
        }
        @include m.desktop {
            width: 33.33%;
        }
    }
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

.cases-content {
    .item {
        margin: 0px;
        float: left;
        border-radius: 0px;
        clear: none;
        overflow: hidden;
        position: relative;
        width: 100%;
        padding: 5px 5px 0px 0px;
        height: 350px;
        @include m.tablet {
            width: 50%;
        }
        @include m.desktop {
            width: 33.33%;
        }
        &.w2 {
            width: 100%;
            @include m.desktop {
                width: 66.66%;
            }
        }
        &.w3 {
            width: 100%;
        }
        a {
            border-radius: 0px;
            border: 0px;
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            transition: all 0.8s;
            @include m.desktop {
                filter: grayscale(100%);
            }
            &:after {
                position: absolute;
                z-index: 100;
                content: '';
                opacity: .35;
                background: rgb(0, 0, 0);
                width: 100%;
                height: 100%;
                left: 0px;
                bottom: 0px;
                transition: all .4s;
                @include m.desktop {
                    height: 0%;
                    opacity: .85;
                    background: rgb(0, 0, 0);
                    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.8s;
            }
            .case-text {
                &.style-1 {
                    position: absolute;
                    z-index: 101;
                    width: 100%;
                    left: 0px;
                    bottom: 0px;
                }
                &.style-2,
                &.style-3 {
                    text-align: center;
                    position: absolute;
                    z-index: 101;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h3 {
                        text-transform: uppercase;
                    }
                }
                h3 {
                    line-height: 1.4em;
                    transition: all .4s;
                    @include m.desktop {
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(100px);
                    }
                }
                p {
                    font-size: .95em;
                    transition: all .8s;
                    @include m.desktop {
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(100px);
                    }
                }
            }
            @include m.desktop {
                &:hover {
                    filter: none;
                    &:after {
                        height: 100%;
                    }
                    img {
                        transform: scale(110%);
                    }
                    .case-text {
                        h3,
                        p {
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(0px);
                        }
                    }
                }
            }
        }
    }
}