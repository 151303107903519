@use 'variables' as v;
@use 'mixins' as m;
html,
body {
    height: 100%;
    width: 100%;
}

body {
    font-family: var(--main-font);
    background: #fff;
    color: #989dbb;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
    letter-spacing: 0.5px;
    @include m.tablet {
        font-size: 14px;
        line-height: 23px;
    }
    @include m.desktop {
        font-size: 16px;
        line-height: 25px;
    }
}

a,
.btn {
    transition: all .2s linear .1s;
}

ul {
    list-style: none;
}

hr {
    display: block;
    margin: 30px 0px;
}

textarea,
input,
input:hover,
input:focus,
input:active,
input[type="text"],
input[type="text"]:focus,
input[type="text"]:hover,
input[type="text"]:active,
input[type="email"],
input[type="email"]:focus,
input[type="email"]:hover,
input[type="email"]:active,
button,
button[type="submit"],
.btn,
.btn:focus,
.btn:active {
    box-shadow: none;
    outline: 0px !important;
}

.no-overflow {
    overflow: hidden !important;
}

.css3animate {
    transition: all .2s linear .1s;
}

.css3animateSlow {
    transition: all 1s linear 0.5s;
}

.clearfix {
    margin: 20px 0px;
}

.hide {
    display: none;
}

.poster-image {
    background-repeat: no-repeat;
    background-size: cover;
    backface-visibility: hidden;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1 !important;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
}

.no-visible {
    display: none;
}

#big-video-wrap {
    position: absolute !important;
}

#video-content {
    position: fixed;
    z-index: -4 !important;
    min-width: 100%;
    min-height: 100%;
    left: 0px;
    top: 0px;
    overflow: hidden;
    opacity: 1;
    background-image: none;
    transition-property: opacity;
    transition-duration: 1500ms;
}

#vimeo_player_wrapper_vimeo_player_video {
    z-index: -4 !important;
    position: absolute !important;
}

#controlBar_bgndVideo .buttonBar {
    display: none;
}

.video-controls {
    position: absolute;
    z-index: 100;
    bottom: 15px;
    right: 20px;
    button {
        opacity: .75;
        border: 0px;
        transition: all .25s;
        background: none;
        text-align: center;
        padding: 0px;
        margin-right: 10px;
        font-size: 1em;
        line-height: 30px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: top;
        cursor: pointer;
    }
}

.light-scheme {
    background: var(--light-back-main-color);
    .video-controls {
        button {
            border: 2px solid var(--light-head-color);
            color: var(--light-head-color);
            &:hover {
                background: var(--light-accent-color);
                border: 2px solid var(--light-accent-color);
                color: var(--light-back-main-color);
            }
        }
    }
    .background-secondary {
        background: var(--light-back-secondary-color);
    }
    .border-top,
    &.border-top {
        border-top: 1px solid var(--light-back-secondary-color)!important;
    }
    .border-bottom,
    &.border-bottom {
        border-bottom: 1px solid var(--light-back-secondary-color)!important;
    }
    .shadow-l24 {
        box-shadow: 0 1rem 2.5rem rgba(var(--light-head-color-rgb), .08);
    }
}

.dark-scheme {
    background: var(--dark-back-main-color);
    .video-controls {
        button {
            border: 2px solid var(--dark-head-color);
            color: var(--dark-head-color);
            &:hover {
                background: var(--dark-accent-color);
                border: 2px solid var(--dark-accent-color);
                color: var(--dark-back-main-color);
            }
        }
    }
    .background-secondary {
        background: var(--dark-back-secondary-color);
    }
    .border-top,
    &.border-top {
        border-top: 1px solid var(--dark-back-secondary-color)!important;
    }
    .border-bottom,
    &.border-bottom {
        border-bottom: 1px solid var(--dark-back-secondary-color)!important;
    }
    .shadow-l24 {
        box-shadow: 0 1rem 2.5rem rgba(var(--dark-head-color-rgb), .08);
    }
}

.back-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}